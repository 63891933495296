<template>
  <ui-component-modal
    :modalTitle="'Edit location logo'"
    :onClickCancel="onClickCancel"
    :disableSaveButton="disableSaveButton"
    :showModal="showModal"
    :onClickSave="saveLogo"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :savingSuccessMessage="savingSuccessMessage"
    :savingErrorMessage="savingErrorMessage"
    :isSavingError="isSavingError"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <Message :message="
          'Make sure the logo has enough resolution to make it look good when used.'" />
      <div class="columns is-mobile is-vcentered">
        <div class="column is-6">
          <ImageCropper
            ref="imageCropper"
            :aspectRatio="NaN"
            :guides="false"
            :viewMode="2"
            previewContainer=".imagePreview"
            :minWidth="200"
            :minHeight="100"
            @callbackImageChosen="val => { newImageChosen = val }"
            @errorCallbackInvalid="val => { hasImageError = val }"
            aspectRatioCssClass="is-3by2"
          />
        </div>
        <div class="column is-5">
          <section class="preview-area">
            <div class="imagePreview" />
          </section>
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Message from '@/components/UI/Message'
import ImageCropper from '@/components/UI/Components/ImageCropper.vue'
import locationProvider from '@/providers/location'
import uploadProvider from '@/providers/upload'
export default {
  components: {
    Message,
    ImageCropper,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
    logoId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingError: false,
      isSavingSuccess: false,
      newImageChosen: false,
      hasImageError: false,
      savingSuccessMessage: 'New logo saved',
      savingErrorMessage: 'Oops something went wrong',
    }
  },

  computed: {
    ...mapState('locationStore', ['location']),

    disableSaveButton() {
      return this.isSaving || !this.newImageChosen || this.hasImageError
    },
  },

  methods: {
    ...mapMutations('locationStore', ['setLocation']),

    /**
     * Save logo
     * First upload new logo
     * Then save new logo to location
     */
    async saveLogo() {
      if (this.location.Id === 0 || this.disableSaveButton) { return }
      this.isSaving = true

      try {
        const NEW_IMAGE = this.$refs.imageCropper.getCroppedCanvas()
        let logoResponse = await uploadProvider.methods.locationLogo(this.location.Id, NEW_IMAGE)
        if (logoResponse.status === 200) {
          let locationImageResponse = await locationProvider.methods.saveLocationLogo(this.location.Id, logoResponse.data)
          this.isSavingSuccess = true

          this.$emit('loadLogo', locationImageResponse.data)

          let t = setTimeout(() => {
            this.onClickCancel()
            clearTimeout(t)
          }, 2000)

        }
      } catch {
        this.isSaving = false
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.imagePreview
  overflow: hidden
  aspect-ratio: 1
  ::v-deep img
    background-color: transparent !important
  &:nth-child(1)
    max-width: 100%
    width: 100%
</style>